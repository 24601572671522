import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { LoginRegister } from "./pages/loginRegister/LoginRegister";
import { CategoryVenuePicker } from "./pages/categoryVenuePicker/CategoryVenuePicker";
import PrivateRoutes from "./PrivateRoutes";
import AuthProvider from "./context/AuthContext";
import { ForgotPassword } from "./pages/forgotPassword/ForgotPassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginRegister pageType="login" />} />
          <Route
            path="/sign-up"
            element={<LoginRegister pageType="register" />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/categories" element={<CategoryVenuePicker />} />
            <Route path="/categories/:id" element={<CategoryVenuePicker />} />
            <Route
              path="/categories/:id/:categoryId"
              element={<CategoryVenuePicker />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
);

reportWebVitals();
