import { ChangeEventHandler, FunctionComponent } from "react";
import "./Input.css";

export interface InputProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  value: string;
  type: "text" | "email" | "password";
}

export const Input: FunctionComponent<InputProps> = ({
  onChange,
  placeholder,
  type,
  value,
}) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
