// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary {
  background-color: transparent;
  border-radius: 4px;
  max-width: 500px;
  border: 1px solid #e1c975;
  color: #e1c975;
  font-size: 16px;

  padding: 16px 24px;
  transition-duration: 300ms;
}
.primary:hover {
  color: black;
  background-color: #e1c975;
}
.fullWidth {
  width: 100%;
}
.secondary {
  color: #e1c975;
  background-color: transparent;
  border: none;
  font-size: 16px;
  transition-duration: 300ms;
  max-width: 618px;
  cursor: pointer;
}
.secondary:hover {
  color: #fff;
}

.secondaryHover {
  color: #fff;
  background-color: transparent;
  border: none;
  transition-duration: 300ms;
  cursor: pointer;
  width: 100%;
  max-width: 618px;
  padding: 16px 24px;
  font-size: 18px;
}
.secondaryHover:hover {
  color: black;
  background-color: #e1c975;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Button.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,eAAe;;EAEf,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,0BAA0B;EAC1B,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".primary {\n  background-color: transparent;\n  border-radius: 4px;\n  max-width: 500px;\n  border: 1px solid #e1c975;\n  color: #e1c975;\n  font-size: 16px;\n\n  padding: 16px 24px;\n  transition-duration: 300ms;\n}\n.primary:hover {\n  color: black;\n  background-color: #e1c975;\n}\n.fullWidth {\n  width: 100%;\n}\n.secondary {\n  color: #e1c975;\n  background-color: transparent;\n  border: none;\n  font-size: 16px;\n  transition-duration: 300ms;\n  max-width: 618px;\n  cursor: pointer;\n}\n.secondary:hover {\n  color: #fff;\n}\n\n.secondaryHover {\n  color: #fff;\n  background-color: transparent;\n  border: none;\n  transition-duration: 300ms;\n  cursor: pointer;\n  width: 100%;\n  max-width: 618px;\n  padding: 16px 24px;\n  font-size: 18px;\n}\n.secondaryHover:hover {\n  color: black;\n  background-color: #e1c975;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
