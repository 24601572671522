import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {  useContext } from "react";
import { AuthContext, AuthProps } from "./context/AuthContext";

const PrivateRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext) as AuthProps;

  return isAuthenticated ? (
      <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location.pathname }} />
  );
};

export default PrivateRoutes;
