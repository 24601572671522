// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  padding: 12px 12px;
  background-color: transparent!important;
  max-width: 474px;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
  border-bottom: 1px solid #fff;
  color: #fff;
}
input:focus {
  outline: none;
  border-bottom: 1px solid #e1c975;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/Input.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,aAAa;EACb,gCAAgC;EAChC,6BAA6B;AAC/B","sourcesContent":["input {\n  padding: 12px 12px;\n  background-color: transparent!important;\n  max-width: 474px;\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  width: 100%;\n  border-bottom: 1px solid #fff;\n  color: #fff;\n}\ninput:focus {\n  outline: none;\n  border-bottom: 1px solid #e1c975;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
