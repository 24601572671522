import { createContext, useState } from "react";

export interface AuthProps {
  isAuthenticated: boolean;
  accessToken: string;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  fbId: string;
  setFbId: React.Dispatch<React.SetStateAction<string>>;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthProps | null>(null);

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() =>
    localStorage.getItem("token") ? true : false
  );
  const [fbId, setFbId] = useState<string>(localStorage.getItem("fbId")??"");
  const [accessToken, setAccessToken] = useState<string>(() => {
    let token = localStorage.getItem("token");
    if (token === null || token === undefined) return "";
    return token;
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        setIsAuthenticated,
        setAccessToken,
        fbId,
        setFbId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
