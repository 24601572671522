// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./WhatsApp Image 2023-09-11 at 11.39.07.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  background-color: #e1c975;
  width: 100vw;
  overflow-x: hidden;
}
.bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.centralBox {
  padding: 24px;

  width: 100%;
  max-width: 80%;

  min-height: 100vh;
  height: 100%;
  background-color: rgb(29, 29, 29);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.secondaryHoverSelected {
  color: black;
  background-color: #e1c975;
}
.title {
  color: #e1c975;
}
.heading {
  display: flex;
  justify-content: space-between;
}
.back {
  color: #7d7d7c;
  /* margin-right: auto;
  margin-bottom: 0px; */
  position: absolute; 
  top: 10px; 
  left: 10px;
  font-size: 13px;
  transition-duration: 300ms;
}

@media screen and (min-width: 520px) {
  .back {
    /* margin-bottom: 0px;*/
     position: absolute; 
     top: 20px; 
     left: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/categoryVenuePicker/CategoryVenuePicker.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,yDAAyE;AAC3E;AACA;EACE,aAAa;;EAEb,WAAW;EACX,cAAc;;EAEd,iBAAiB;EACjB,YAAY;EACZ,iCAAiC;;EAEjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,cAAc;EACd;uBACqB;EACrB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE;IACE,uBAAuB;KACtB,kBAAkB;KAClB,SAAS;KACT,UAAU;EACb;AACF","sourcesContent":[".container {\n  background-color: #e1c975;\n  width: 100vw;\n  overflow-x: hidden;\n}\n.bg {\n  background-image: url(\"./WhatsApp\\ Image\\ 2023-09-11\\ at\\ 11.39.07.jpeg\");\n}\n.centralBox {\n  padding: 24px;\n\n  width: 100%;\n  max-width: 80%;\n\n  min-height: 100vh;\n  height: 100%;\n  background-color: rgb(29, 29, 29);\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 16px;\n}\n.secondaryHoverSelected {\n  color: black;\n  background-color: #e1c975;\n}\n.title {\n  color: #e1c975;\n}\n.heading {\n  display: flex;\n  justify-content: space-between;\n}\n.back {\n  color: #7d7d7c;\n  /* margin-right: auto;\n  margin-bottom: 0px; */\n  position: absolute; \n  top: 10px; \n  left: 10px;\n  font-size: 13px;\n  transition-duration: 300ms;\n}\n\n@media screen and (min-width: 520px) {\n  .back {\n    /* margin-bottom: 0px;*/\n     position: absolute; \n     top: 20px; \n     left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
