import { FunctionComponent, useContext, useState } from "react";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import GOA from "./GOA_logowhite 1.png";
import PoweredBy from "./OL_POWEREDBY_80.png";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "@firebase/auth";

import "./LoginRegister.css";
import { auth } from "../../firebase";
import { AuthContext, AuthProps } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import axios from "axios";
import {
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

export interface LoginRegisterProps {
  pageType: "login" | "register";
}

export const LoginRegister: FunctionComponent<LoginRegisterProps> = ({
  pageType,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fullName, setFullName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setAccessToken, setIsAuthenticated, setFbId } = useContext(
    AuthContext,
  ) as AuthProps;
  const googleProvider = new GoogleAuthProvider();
  // const facebookProvider = new FacebookAuthProvider();
  return (
    <div className="container">
      <div className="centralBox">
        <a className="img-container" href="https://goldenowlawards.com/">
          <img src={GOA} alt="goa" />
        </a>
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {pageType === "register" && (
          <Input
            placeholder="Full name"
            value={fullName}
            type="text"
            onChange={(e) => setFullName(e.target.value)}
          />
        )}
        <Input
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {pageType === "register" && (
          <Input
            type="password"
            value={confirmPassword}
            placeholder="Confirm your password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        )}
        {error && <p className="error">{errorMsg}</p>}
        <Button
          className="loginButton"
          variant="primay"
          // disabled={true}
          fullWidth
          onClick={() => {
            if (email && password && pageType === "login")
              // alert("Voting is now closed!");
              signInWithEmailAndPassword(auth, email, password)
                .then(async (user) => {
                  if (user.user) {
                    const userToken = await user.user.getIdToken();
                    setAccessToken(userToken);
                    setIsAuthenticated(true);
                    setFbId(user.user.uid);
                    localStorage.setItem("token", userToken);
                    localStorage.setItem("fbId", user.user.uid);
                    navigate("/categories");
                  }
                })
                .catch(() => {
                  setError(true);
                  setErrorMsg("Wrong email/password!");
                  setTimeout(() => {
                    setError(false);
                  }, 2000);
                });
            if (
              email &&
              password &&
              password === confirmPassword &&
              pageType === "register"
            ) {
              // alert("Voting is now closed!");
              createUserWithEmailAndPassword(auth, email, password)
                .then(async (user) => {
                  if (user.user) {
                    await axios.post(
                      `${process.env.REACT_APP_API_BASE_URL}/voters`,
                      {
                        FB_ID: user.user.uid,
                        name: fullName,
                        email: email,
                      },
                    );
                    const userToken = await user.user.getIdToken();
                    setAccessToken(userToken);
                    setIsAuthenticated(true);
                    setFbId(user.user.uid);
                    localStorage.setItem("token", userToken);
                    localStorage.setItem("fbId", user.user.uid);
                    navigate("/categories");
                  }
                })
                .catch((error) => {
                  setError(true);
                  console.log(error);
                  console.log(error.code);
                  if (error.code === "auth/email-already-in-use") {
                    setErrorMsg("Email already in use!");
                  } else {
                    setErrorMsg("An error ocurred!");
                  }
                  setTimeout(() => {
                    setError(false);
                  }, 2000);
                });
            }
          }}
        >
          {pageType === "login" ? "Login" : "Signup"}
        </Button>
        <button
          type="button"
          onClick={() => {
            // alert("Voting is now closed!");
            signInWithPopup(auth, googleProvider).then(async (user) => {
              if (user) {
                await axios
                  .post(`${process.env.REACT_APP_API_BASE_URL}/voters`, {
                    FB_ID: user.user.uid,
                    name: user.user.displayName,
                    email: user.user.email,
                  })
                  .then(async () => {
                    const userToken = await user.user.getIdToken();
                    setAccessToken(userToken);
                    setIsAuthenticated(true);
                    setFbId(user.user.uid);
                    localStorage.setItem("token", userToken);
                    localStorage.setItem("fbId", user.user.uid);
                    navigate("/categories");
                  });
              }
            });
          }}
          className="login-with-google-btn"
        >
          Sign in with Google
        </button>

        {/* 
        <button
          type="button"
          onClick={async () => {
            signInWithRedirect(auth, facebookProvider).catch((e) => {
              alert("Your login has filed");
            });
            const userCred = await getRedirectResult(auth);
            if (userCred) {
              await axios
                .post(`${process.env.REACT_APP_API_BASE_URL}/voters`, {
                  FB_ID: userCred.user.uid,
                  name: userCred.user.displayName,
                  email: userCred.user.email,
                })
                .then(async () => {
                  const userToken = await userCred.user.getIdToken();
                  console.log("token je " + userToken);
                  setAccessToken(userToken);
                  setIsAuthenticated(true);
                  setFbId(userCred.user.uid);
                  localStorage.setItem("token", userToken);
                  localStorage.setItem("fbId", userCred.user.uid);
                  navigate("/categories");
                });
            }
          }}
          className="login-with-facebook-btn"
        >
          Sign in with Facebook
        </button> */}
        <Button
          variant="secondary"
          onClick={() => {
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            pageType === "login" ? navigate("/sign-up") : navigate("/");
          }}
        >
          {pageType === "login" ? "Create your account" : "Go back to login"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            // alert("Voting is now closed!");
            navigate("/forgot-password");
          }}
        >
          {"Forgot Your Password?"}
        </Button>
        <a href="https://getonelight.com/" rel="noreferrer" target="_blank">
          <img
            className="powered-img"
            src={PoweredBy}
            alt="powered by onelight"
          />
        </a>
      </div>
    </div >
  );
};
