import { FunctionComponent, MouseEventHandler } from "react";
import "./Button.css";

export interface ButtonProps {
  variant: "primay" | "secondary" | "secondaryHover";
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}
export const Button: FunctionComponent<ButtonProps> = ({
  children,
  variant,
  className,
  fullWidth,
  disabled,
  onClick,
}) => {
  return (
    <button
      className={`${variant === "primay" && "primary"} ${
        variant === "secondary" && "secondary"
      } ${variant === "secondaryHover" && "secondaryHover"} ${
        fullWidth && "fullWidth"
      } ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
