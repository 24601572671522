import { ApiResponse } from "./CategoryVenuePicker";

export const formatData = (
  data: ApiResponse,
  id?: string,
  categoryId?: string
) => {
  const categories = data.data[0].categories;

  if (categoryId) {
    const filtered = categories
      .filter((item) => `${item.id}` === id)[0]
      .categories?.filter((cat) => `${cat.id}` === categoryId)[0];

    return filtered?.options.map((item) => {
      return {
        id: item.id,
        name: item.name,
        subId: item.category_id,
        vote: item.vote,
      };
    });
  }

  if (id)
    return categories
      .filter((item) => `${item.id}` === id)[0]
      .categories?.map((item) => {
        return { id: item.id, name: item.name, subId: item.parent, vote: null };
      });

  return categories?.map((item) => {
    return { id: item.id, name: item.name, subId: item.parent, vote: null };
  });
};
