import { FunctionComponent, useState } from "react";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import GOA from "../loginRegister/GOA_logowhite 1.png";
import PoweredBy from "../loginRegister/OL_POWEREDBY_80.png";

import "../loginRegister/LoginRegister.css";

import { useNavigate } from "react-router";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

export const ForgotPassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <div className="container">
      {open && (
        <div className="modal">
          <div className="forgot-password-title ">Your password reset email has been sent</div>
          <Button
            variant="primay"
            onClick={() => {
              navigate("/");
            }}
          >
            {"Go back to login"}
          </Button>
        </div>
      )}
      <div className="centralBox">
        <a className="img-container" href="https://getonelight.com/">
          <img src={GOA} alt="goa" />
        </a>
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {error && <p className="error">Wrong email or password!</p>}
        <Button
          className="loginButton"
          variant="primay"
          fullWidth
          disabled={!email}
          onClick={() => {
            if (email) {
              sendPasswordResetEmail(auth, email)
                .then(() => {
                  setError("");
                  setEmail("");
                  setOpen(true);
                })
                .catch((error: any) => {
                  setError(error.message);
                });
            }
          }}
        >
          {"Send Password Reset"}
        </Button>

        <Button
          variant="secondary"
          onClick={() => {
            navigate("/");
          }}
        >
          {"Go back to login"}
        </Button>
        <a href="https://getonelight.com/" rel="noreferrer" target="_blank">
          <img
            className="powered-img"
            src={PoweredBy}
            alt="powered by onelight"
          />
        </a>
      </div>
    </div>
  );
};
