import { useNavigate, useParams } from "react-router";
import "./CategoryVenuePicker.css";
import { useEffect, useState, useContext } from "react";
import { Button } from "../../components/button/Button";
import axios from "axios";
import { formatData } from "./CategoryVenuePicker.utils";
import { AuthContext, AuthProps } from "../../context/AuthContext";
import GOLDENOWL from "./GOA_FOLLOWOWL_250.png";
interface CategoryOption {
  id: number;
  vote: Vote | null;
  name: string;
  order: string;
  category_id: number;
  created_at: string;
  updated_at: string;
}
interface Vote {
  id: number;
  user_id: string;
  option_id: number;
  created_at: Date;
  updated_at: Date;
}
interface Category {
  id: number;
  name: string;
  url: string;
  description: string;
  poll_id: number;
  level: number;
  parent: number;
  status: string;
  created_at: string | null;
  updated_at: string;
  order: string;
  categories: Category[] | null;
  options: CategoryOption[];
}

interface Event {
  id: number;
  name: string;
  url: string;
  beginning: string;
  ending: string;
  created_at: string | null;
  updated_at: string;
  categories: Category[];
}

interface ActivePoll {
  id: number;
  name: string;
  url: string;
  beginning: string;
  ending: string;
  created_at: string | null;
  updated_at: string;
}
interface NightclubCategory {
  id: number;
  name: string;
  url: string;
  description: string;
  poll_id: number;
  level: number;
  parent: {
    id: number;
    name: string;
    url: string;
    description: string;
    poll_id: number;
    level: number;
    parent: number;
    status: string;
    created_at: string;
    updated_at: string;
    order: string;
    poll: {
      id: number;
      name: string;
      url: string;
      beginning: string;
      ending: string;
      created_at: string | null;
      updated_at: string;
    };
  };
  status: string;
  created_at: string;
  updated_at: string;
  order: string;
  options: NightclubOption[];
}

interface NightclubOption {
  id: number;
  name: string;
  order: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  vote: {
    id: number;
    user_id: string;
    option_id: number;
    created_at: string;
    updated_at: string;
  };
}

interface PollData {
  category: NightclubCategory;
  next: number;
  fbId: string;
}

export interface ApiResponse {
  data: Event[];
}
export const CategoryVenuePicker = () => {
  const { id, categoryId } = useParams();
  const navigate = useNavigate();
  const { fbId } = useContext(AuthContext) as AuthProps;
  const [data, setData] = useState<ApiResponse>();
  const [cat, setCat] = useState<PollData>();
  const [selected, setSelected] = useState<NightclubOption | null>();

  useEffect(() => {
    if (!categoryId) {
      axios
        .get<ApiResponse>(`${process.env.REACT_APP_API_BASE_URL}/categories`)
        .then((res) => {
          setData(res.data);
        });
      axios
        .get<ActivePoll>(`${process.env.REACT_APP_API_BASE_URL}/polls`)
        .then((res) => {
          if (data)
            setData({
              data: data?.data.filter((item) => item.id === res.data.id),
            });
        });
    }
    if (categoryId) {
      axios
        .get<PollData>(
          `${process.env.REACT_APP_API_BASE_URL}/categories/${categoryId}/user/${fbId}`
        )
        .then((res) => {
          setCat(res.data);
          setSelected(
            res.data?.category.options.find((item) => item.vote !== null) ??
            null
          );
          console.log(data);
        });
    }
  }, [categoryId]);
  console.log(selected);
  return (
    <div className="container">
      <div className="centralBox bg">
        {id && (
          <Button
            variant="secondary"
            className="back"
            onClick={() =>
              navigate(categoryId ? `/categories/${id}` : "/categories")
            }
          >
            <h2>{"< Back"}</h2>
          </Button>
        )}
        <div className="heading">
          {categoryId && <h2 className="title">{cat?.category.name}</h2>}
          {id && !categoryId && <h2 className="title">{data?.data[0].name}</h2>}

        </div>
        {!categoryId &&
          data &&
          formatData(data, id)?.map((item) => (
            <Button
              className={`${selected?.id === item.id ? "secondaryHoverSelected" : ""
                }`}
              key={item.id}
              onClick={() => {
                if (id) {
                  navigate(`/categories/${id}/${item.id}`);
                } else navigate(`/categories/${item.id}`);
              }}
              variant="secondaryHover"
            >
              {item.name}
            </Button>
          ))}
        {categoryId &&
          cat?.category.options.map((item) => (
            <Button
              className={`${selected?.id === item.id ? "secondaryHoverSelected" : ""
                }`}
              key={item.id}
              onClick={() => {
                if (categoryId) setSelected(item);
                else if (id) {
                  navigate(`/categories/${id}/${item.id}`);
                } else navigate(`/categories/${item.id}`);
              }}
              variant="secondaryHover"
            >
              {item.name}
            </Button>
          ))}
        {categoryId && (
          <Button
            onClick={() => {
              if (selected)
                axios
                  .post(`${process.env.REACT_APP_API_BASE_URL}/votes`, {
                    user_id: fbId,
                    subcategory_id: selected?.category_id,
                    option_id: selected?.id,
                    option_voted_id:
                      cat?.category.options.find((item) => item.vote !== null)
                        ?.id ?? null,
                  })
                  .then((res) => {
                    if (!cat?.next) navigate(`/categories/`);

                    navigate(`/categories/${id}/${cat?.next}`);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
            }}
            variant="primay"
          >
            Vote
          </Button>
        )}
        {categoryId && (
          <Button
            onClick={() => {
              navigate(`/categories/${id}/${cat?.next}`);
            }}
            variant="secondary"
          >
            Skip
          </Button>
        )}
        <>
          <div style={{ height: '64px' }}></div>
          <a style={{ display: 'inline-block' }} href="https://www.instagram.com/goldenowlawards" target="_blank" rel="noreferrer">
            <img src={GOLDENOWL} alt="Golden Owl" style={{ width: '200px', height: 'auto', display: 'inline-block', margin: '0' }} />
          </a>
          <div style={{ height: '64px' }}></div>
        </>
      </div>
    </div>
  );
};
